import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Router } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { HistoryContext } from '.';
import { AlertBar, Footer, ModalDialog, Navigation } from './components';
import { useSessionize } from './hooks/useSessionize';
import { CodeOfConduct } from './pages/CodeOfConduct';
import { Home } from './pages/Home';
import { Sponsors } from './pages/Sponsors';
import { Discord } from './pages/Discord';
import { ConfigState } from './states/ConfigState';

export interface IMainProps {}

export const Main: React.FunctionComponent<IMainProps> = (props: IMainProps) => {
  const [ config ] = useRecoilState(ConfigState);
  const history = React.useContext(HistoryContext);
  useSessionize();
  const [ gInitialized, setGoogleInit ] = React.useState(false);

  const loadGoogle = async () => {
    if (!gInitialized && config && config.googleAnalytics && history) {
      const ReactGA: any = await import(/* webpackChunkName: 'react-ga' */ 'react-ga');
    
      ReactGA.initialize(config.googleAnalytics);
      ReactGA.pageview(window.location.pathname);
      setGoogleInit(true);
      
      (history as any).listen((location: any, action: any) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    }
  };

  React.useEffect(() => {
    loadGoogle();
    // eslint-disable-next-line
  }, ['', config]);

  return (
    <>
      <Helmet>
        { config && config.title && <title>{config.title}</title> }
        { config && config.description && <meta name="description" content={config.description || ""} /> }
      </Helmet>

      <div className="w-full">
        <Navigation />

        <AlertBar />

        <Router history={history as any}>
          <Route exact path="/" component={Home}  />
          <Route path="/sponsors" component={Sponsors}  />
          <Route path="/code-of-conduct" component={CodeOfConduct}  />
          <Route path="/discord" component={Discord}  />
          <Route path="/channels/:channel" component={Home}  />
        </Router>

        <Footer />

        <ModalDialog />
      </div>
    </>
  );
};