import * as React from 'react';
import { SpeakerInfo } from '../models';
import { Speaker } from './Speaker';

export interface ISpeakersProps {
  speakers: SpeakerInfo[];
}

export const Speakers: React.FunctionComponent<ISpeakersProps> = (props: ISpeakersProps) => {
  const { speakers } = props;

  if (!speakers || speakers.length <= 0) {
    return null;
  }
  
  return (
    <p className="grid__session__speaker leading-relaxed text-gray-700 mb-4">
      <svg className="icon mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="microphone-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M336 192h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16zM176 352c53.02 0 96-42.98 96-96h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272v-32h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272v-32h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96z"></path></svg>
      
      {
        speakers && (
          speakers.map((s, idx) => <Speaker key={s.id} idx={idx} info={s} />)
        )
      }
    </p>
  );
};