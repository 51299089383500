import * as React from 'react';
import { LiveSession, Sponsors, ChannelOverview, Schedule, SpeakersOverview } from '../components';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ConfigState } from '../states/ConfigState';

export interface IHomeProps {}

export const Home: React.FunctionComponent<IHomeProps> = (props: IHomeProps) => {
  const [ config ] = useRecoilState(ConfigState);
  let { channel } = useParams<{ channel: string | undefined }>();

  let crntChannel = config && config.channels[0];
  if (config && channel) {
    crntChannel = config.channels.find(c => c.id.toLowerCase() === (channel as string).toLowerCase()) || config.channels[0];
  }

  return (
    <>
      <LiveSession channel={crntChannel} />

      <Sponsors />

      <ChannelOverview channel={crntChannel} />

      <Schedule />

      <SpeakersOverview />
    </>
  );
};